import { Component, inject } from '@angular/core';
import { AlertService } from '../../core/services/alert.service';

@Component({
    selector: 'spl-alerts',
    standalone: true,
    imports: [],
    templateUrl: './alerts.component.html',
    styleUrl: './alerts.component.scss'
})
export class AlertsComponent {
    private alertService: AlertService = inject(AlertService);

    get alerts() {
        return this.alertService.alerts;
    }
}
